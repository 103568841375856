'use client';

import { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { TimelineLite, TweenLite } from 'gsap';
import { usePathname } from 'next/navigation';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import { Page, Text, CustomImage, GlobalSlider, Title, CustomLink, Button, Lottie } from '@/components/common';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import fish from '@/lottieAnimation/fish.json';
import dragon from '@/lottieAnimation/dragon.json';
import muramonyFish from '@/lottieAnimation/homeFish.json';

//* Style
import HomeStyle from '@/styles/pagesStyles/HomeStyle';

const HomeTemplate = ({ pageData, winWidth }) => {
    //! Next Navigation
    const pathname = usePathname();

    //! States
    const [secObj, setSecObj] = useState(false);
    const [lottieStart, setLottieStart] = useState(true);
    const [loaderState, setLoaderState] = useState('');

    //! Refs
    const BottomContRef = useRef();
    const topTicker1 = useRef();
    const topTicker2 = useRef();
    const HomeBottomImgRef = useRef();
    const backRoundRef = useRef();
    const HomeBottomText1Ref = useRef();
    const HomeBottomText2Ref = useRef();
    const HomeBottomImg2Ref = useRef();
    const loaderRef = useRef();
    const HomeBottomImg3Ref = useRef();
    const CursorRef = useRef();
    const CursorRefOther = useRef();

    //! Routes from Config
    const routes = useMemo(() => config.routes, []);

    //! Global Variables
    const tl2 = useMemo(() => new TimelineLite({ ease: 'power2.out', paused: true }), []);

    useEffect(() => {
        setLoaderState('start');

        setTimeout(() => {
            setLoaderState('end');
        }, 2200);
    }, []);

    useEffect(() => {
        if (loaderState === 'end') {
            setLottieStart(false);

            tl2.progress(0).clear();

            tl2.to(topTicker1.current, {
                rotate: '0',
                x: '0%',
                duration: 1,
                ease: 'power4.out',
            })
                .to(topTicker2.current, {
                    rotate: '0',
                    x: '0%',
                    delay: -1,
                    duration: 1,
                    ease: 'power4.out',
                })
                .to(backRoundRef?.current, {
                    scale: 1,
                    duration: 1,
                    delay: -1.1,
                });

            tl2.play();
        }
    }, [pathname, pageData, loaderState]);

    const sectionAnime = useCallback(() => {
        const elTop = BottomContRef?.current?.getBoundingClientRect();
        const pointStart = window.innerHeight;

        if (elTop?.top < pointStart) {
            setSecObj(true);

            if (elTop.top + 500 > 0 && elTop.top > 0) {
                TweenLite.set(HomeBottomText1Ref.current?.firstChild, { x: `-${elTop.top}` });
                TweenLite.to(HomeBottomImgRef.current, 0.8, { y: elTop.top, scale: 1 });
                TweenLite.to(HomeBottomImg2Ref.current, 0.6, { y: elTop.top, scale: 1, opacity: 1 });
                TweenLite.to(HomeBottomImg3Ref.current, 0.2, { y: elTop.top, scale: 1, opacity: 1 });
                TweenLite.set(HomeBottomText2Ref.current?.firstChild, { x: elTop.top });
            } else {
                TweenLite.to(HomeBottomImgRef.current, 0.8, { y: 0, scale: 1 });
                TweenLite.to(HomeBottomImg2Ref.current, 0.6, { y: 0, scale: 1, opacity: 1 });
                TweenLite.to(HomeBottomImg3Ref.current, 0.2, { y: 0, scale: 1, opacity: 1 });
            }
        } else {
            setSecObj(false);
        }
    }, [secObj]);

    useEffect(() => {
        window.addEventListener('scroll', sectionAnime);

        return () => {
            window.removeEventListener('scroll', sectionAnime);
        };
    }, [sectionAnime]);

    const mouseOver = useCallback(
        (e) => {
            if (winWidth >= 1024 && e.clientX) {
                TweenLite.to([CursorRef.current, CursorRefOther.current.childNodes], 0.1, {
                    stagger: 0.01,
                    css: {
                        top: e.clientY,
                        left: e.clientX,
                        position: 'fixed',
                        scale: 1,
                    },
                });

                if (e.clientX >= winWidth / 2) {
                    TweenLite.set(CursorRef.current.children[0], { rotation: e.clientX / 50 });
                } else {
                    TweenLite.set(CursorRef.current.children[0], { rotation: (e.clientX + winWidth / 2) / -50 });
                }
            }
        },
        [winWidth]
    );

    const mouseOut = useCallback(() => {
        if (winWidth >= 1024) {
            TweenLite.to([CursorRef.current, CursorRefOther.current.childNodes], {
                scale: 0,
            });
        }
    }, []);

    return (
        <Page className='Home'>
            {loaderState !== 'end' ? (
                <div
                    ref={loaderRef}
                    className={`loader homeLoader ${loaderState}`}>
                    <div className='cont'>
                        <span className='line-top' />
                        <div className='circle' />
                        <span className='line-bottom' />
                    </div>
                </div>
            ) : null}

            <HomeStyle>
                <div className='overflowWrap'>
                    <div className='HomeTop FlexBox alignMiddle'>
                        <CustomLink
                            shallow
                            url={config.routes.products.path}>
                            <div
                                className='cursorWrapp'
                                onMouseMove={(e) => mouseOver(e)}
                                onMouseLeave={(e) => mouseOut(e)}>
                                <div
                                    className={'cursorDrag FlexBox alignMiddle  alignCenter'}
                                    ref={CursorRef}>
                                    <Text
                                        className={'h9 white arm-regular uppercase '}
                                        text='ShopNow'
                                    />
                                </div>

                                <div
                                    className='copysWrap'
                                    ref={CursorRefOther}>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                    <div className={'cursorDrag'}></div>
                                </div>
                            </div>
                        </CustomLink>

                        <Text
                            tag={'span'}
                            className={'CollNumber'}
                            children={
                                <a
                                    href='tel:+374 11 388888'
                                    className='h8 white arm-regular'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    +374 11 388888
                                </a>
                            }
                        />

                        <h1 className='homeTopTickerCont'>
                            <span
                                className='homeTopTicker1'
                                ref={topTicker1}>
                                <Text
                                    tag={'span'}
                                    className={'h1 white arm-regular'}
                                    text={'homeTopTicker'}
                                    uppercase={true}
                                />
                            </span>

                            <span
                                className='homeTopTicker2'
                                ref={topTicker2}>
                                <Text
                                    tag={'span'}
                                    className={'h1 white arm-regular'}
                                    text={'homeTopTickerBottom'}
                                    uppercase={true}
                                />
                            </span>
                        </h1>

                        <div className='FlexBox alignCenter relativeWrap'>
                            <div className='HomeTopRiceWrapp'>
                                <CustomImage
                                    src={'/images/homeIcons/riceTop.png'}
                                    alt={'rice'}
                                    className={'HomeTopRice'}
                                />
                            </div>

                            <div className='HomeTopImgCont'>
                                {winWidth >= 1024 ? (
                                    <Lottie
                                        loop={false}
                                        animData={dragon}
                                        isPlay={!lottieStart}
                                        isPaused={lottieStart}
                                        className={'DragonImg'}
                                    />
                                ) : (
                                    <CustomImage
                                        alt={'dragon'}
                                        className={'DragonImg'}
                                        src={'/images/homeIcons/MurakamiDragon.svg'}
                                    />
                                )}

                                <Lottie
                                    loop
                                    autoplay
                                    animData={fish}
                                    className={'CrookedImg'}
                                />

                                <div
                                    className='backRound'
                                    ref={backRoundRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <GlobalSlider
                    data={pageData.products}
                    sliderTitle='MostWanted'
                />

                <div className='muramonyTopSection'>
                    <div className='muramonyWrap FlexBoxContainer'>
                        {winWidth < 1024 ? (
                            <div className='muramonyRight'>
                                <CustomImage
                                    src={'/images/homeIcons/muramonyImg2.png'}
                                    alt={'propd Img'}
                                    className={'CrookedImg muramonyImg'}
                                />
                            </div>
                        ) : null}

                        <div className='muramonyLeft'>
                            <Title
                                tag={'h2'}
                                contClassName={'title'}
                                className={'arm-regular'}
                                text={pageData.muramoney_title}
                            />

                            <Text
                                tag={'p'}
                                className={'description h10 arm-regular'}
                                text={pageData.muramoney_text}
                            />
                        </div>

                        {winWidth >= 1024 ? (
                            <div className='muramonyRight'>
                                <Lottie
                                    loop
                                    autoplay
                                    animData={muramonyFish}
                                    className={'CrookedImg muramonyImg'}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='btnCont'>
                        <Button
                            className={'btn-large btn-black btn-doubleText'}
                            splitBtn={'LearnMore'}
                            btnLink={routes.muramoney.path}
                        />
                    </div>
                </div>

                <div className='overflowWrap'>
                    <div
                        className='HomeBottom'
                        ref={BottomContRef}>
                        <div
                            className='HomeBottomImgWrap'
                            ref={HomeBottomImgRef}>
                            <CustomImage
                                src={'/images/homeIcons/propd3.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg1'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd9.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg2'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd1.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg3'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd6.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg4'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd5.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg5'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd4.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg6'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd7.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg7'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd2.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg8'}
                            />
                            <CustomImage
                                src={'/images/homeIcons/propd8.svg'}
                                alt={'propd Img'}
                                className={'propdImg propdImg9'}
                            />
                        </div>

                        <h2 className='HomeBottomTextWrap'>
                            <span
                                className='HomeBottomText1'
                                ref={HomeBottomText1Ref}>
                                <Text
                                    tag={'span'}
                                    className={'h1 white arm-regular'}
                                    text={'homeLastSectionText1'}
                                    uppercase={true}
                                />
                            </span>

                            <span
                                className='HomeBottomText2 FlexBox JustifyEnd'
                                ref={HomeBottomText2Ref}>
                                <Text
                                    tag={'span'}
                                    className={'h1 white arm-regular'}
                                    text={'homeLastSectionText2'}
                                    uppercase={true}
                                />
                            </span>
                        </h2>

                        <div
                            className='HomeBottomBackImgWrap'
                            ref={HomeBottomImg2Ref}>
                            <CustomImage
                                src={'/images/homeIcons/cloud.png'}
                                alt={'cloud Img'}
                                className={'HomeBottomBackImg'}
                            />
                        </div>

                        <div
                            className='HomeBottomBackImgWrap'
                            ref={HomeBottomImg3Ref}>
                            <CustomImage
                                src={'/images/homeIcons/rice.png'}
                                alt={'rice'}
                                className={'HomeBottomBackImg'}
                            />
                        </div>
                    </div>
                </div>
            </HomeStyle>
        </Page>
    );
};

export default withUIContext(HomeTemplate, ['winWidth']);
