import styled from 'styled-components';

const HomeStyle = styled.div`
	--shopNowFont: 20px;
	--letterSpaceTop: 0.125em;

	--HomeTopMargTop: var(--sp4x);
	--HomeTopMargBot: var(--sp14x);
	--ShopNowContMaxWidth: 300px;
	--ShopNowContPad: var(--sp1-5x);
	--ShopNowContPadLeft: var(--sp2x);
	--DragonImgMaxWidth: 560px;
	--backRoundSize: 100vw;

	--ShopNowTickerContMarg: var(--sp7x);
	--ShopNowTickerPadd: var(--sp1-5x);
	--ShopNowTickerItem: var(--sp2-5x);

	--HomeBottomMarg: var(--sp8x);
	--HomeBottomHeight: 500px;

	--muramonyWrapPaddingLeft: var(--containerPadding);
	--muramonyWrapPaddingRight: var(--containerPadding);
	--muramonyLeftMargBot: calc(var(--marginBottom) / 1.4);
	--muramonyTitleMarg: var(--sp5x);
	--muramonyLeftWidth: 100%;
	--muramonyRightWidth: 100%;

	--circleSize: var(--sp15x);

	.overflowWrap:first-child {
		margin-top: var(--HomeTopMargTop);
	}

	.HomeTop {
		position: relative;
		padding-bottom: var(--HomeTopMargBot);
		min-height: 70vh;

		.cursorWrapp {
			position: absolute;
			top: var(--marginTop);
			bottom: var(--marginTop);
			left: var(--containerPadding);
			right: var(--containerPadding);
			z-index: 10;
			> .cursorDrag {
				box-shadow: 6px 9px 20px 5px hsl(329deg 100% 50% / 70%);
			}
			.cursorDrag {
				pointer-events: none;
				position: absolute;
				text-align: center;
				z-index: 7;
				top: 94%;
				left: 60%;
				width: var(--circleSize);
				height: var(--circleSize);
				transform: translate3d(-50%, -50%, 0);
				border-radius: 200px;
				background: linear-gradient(0.25turn, #a700de, #fe0085);
				> p {
					transform: rotate(-21deg);
					transition: all var(--trTime);
				}
			}
			.copysWrap {
				.cursorDrag {
					z-index: 2;
				}
			}
		}

		.CollNumber {
			position: absolute;
			z-index: 9;
			bottom: 0;
			left: var(--headerPaddingLeft);
			letter-spacing: 3px;
			a {
				display: block;
				transform: rotate(-90deg);
				transform-origin: 0 0;
			}
		}

		/* .CollNumber{
            position: absolute;
            z-index: 10;
            bottom:0;
            right:calc(var(--headerPaddingLeft) + var(--sp3x));
            transform:rotate(90deg);
            transform-origin: 100% 0%;
            z-index:5;
        } */

		.HomeTopRiceWrapp {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 0;

			.HomeTopRice {
				width: 100%;
				height: 100%;

				img {
					object-fit: contain;
				}
			}
		}

		.homeTopTickerCont {
			position: absolute;
			top: 50%;
			left: 0%;
			right: 0%;
			transform: translateY(-100%);
			display: flex;
			flex-direction: column;

			.homeTopTicker1,
			.homeTopTicker2 {
				white-space: nowrap;
				display: inline-flex;
				padding: 0 var(--headerPaddingLeft);
				letter-spacing: var(--letterSpaceTop);
				.comp-title {
					line-height: var(--lineHeightXS);
				}
			}
			.homeTopTicker1 {
				transform: translateX(25%) rotate(-7deg);
				transform-origin: 25% 0;
			}
			.homeTopTicker2 {
				align-self: flex-end;
				transform: translateX(-25%) rotate(-7deg);
				transform-origin: 75% 0;
			}
			& + .FlexBox {
				width: 100%;
			}
		}

		.HomeTopImgCont {
			position: relative;
			width: 100%;
			max-width: var(--backRoundSize);

			.DragonImg {
				position: relative;
				padding: 50%;
				top: 50%;
				left: 50%;
				width: 100%;
				transform: translate(-50%, -40%);

				.image {
					object-fit: contain;
				}
			}

			.CrookedImg {
				position: absolute;
				z-index: -1;
				right: 0;
				transform: translate(-50%, 0%);
				padding: 56% 70%;
				top: 0%;
				left: 50%;
				width: 100%;
			}

			.backRound {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.8);
				bottom: 0;
				border-radius: 1000px;
				background-color: #1c2118;
				overflow: hidden;
				z-index: -2;
				width: calc(var(--backRoundSize) - (var(--containerPadding) * 2));
				height: calc(var(--backRoundSize) - (var(--containerPadding) * 2));
				max-width: var(--DragonImgMaxWidth);
				max-height: var(--DragonImgMaxWidth);
			}
		}
	}

	.muramonyTopSection {
		margin: var(--marginBottom) 0;
		.muramonyWrap {
			padding: 0 var(--muramonyWrapPaddingRight) 0 var(--muramonyWrapPaddingLeft);
			.muramonyLeft {
				flex: 0 0 var(--muramonyLeftWidth);
				margin-bottom: var(--muramonyLeftMargBot);
				.title {
					margin-bottom: var(--muramonyTitleMarg);
				}
			}
			.muramonyRight {
				margin-bottom: var(--muramonyTitleMarg);
				flex: 0 0 var(--muramonyRightWidth);
				.muramonyImg {
					padding-top: 100%;
					position: relative;
				}
			}
		}
		.btn.btn-black {
			border-right: 0;
			border-left: 0;
		}
	}

	.ShopNowTickerCont {
		margin: var(--ShopNowTickerContMarg) 0;
		white-space: nowrap;
		position: relative;

		.comp-title {
			font-size: var(--shopNowFont);
		}

		.ShopNowTickerBlack {
			display: block;
			width: 106%;
			margin-left: -3%;
			transform: rotate(-7deg);
			background: var(--black);
			border-top: 2px solid var(--white);
			border-bottom: 2px solid var(--white);
			padding: var(--ShopNowTickerPadd) var(--ShopNowContPadLeft);
			transform-origin: 50% 0;
			> span {
				display: block;
				width: 100%;
				> div {
					width: max-content;
				}
			}
		}
		.ShopNowTickerWhite {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 106%;
			margin-left: -3%;
			transform: rotate(7deg);
			background: var(--white);
			padding: var(--ShopNowTickerPadd) var(--ShopNowContPadLeft);
			border-top: 2px solid var(--black);
			border-bottom: 2px solid var(--black);
			transform-origin: 50% 0;
			> span {
				display: block;
				width: 100%;
				> div {
					width: max-content;
				}
			}
		}

		.comp-title {
			padding: 0 var(--ShopNowTickerItem);
		}
	}

	.HomeBottom {
		margin: var(--HomeBottomMarg) 0 var(--HomeBottomMarg);
		position: relative;
		height: var(--HomeBottomHeight);
		min-height: 80vh;

		.HomeBottomBackImgWrap {
			position: absolute;
			top: -10%;
			bottom: 0;
			right: 0;
			left: 0;
			opacity: 0;
			z-index: -1;
			transform: translate3d(0, 100%, 0) scale(0.6);
			.HomeBottomBackImg {
				width: 100%;
				height: 100%;
			}
		}

		.HomeBottomImgWrap {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: 0 auto;
			max-width: 500px;
			transform: translate3d(0, 100%, 0) scale(0.6);

			.propdImg {
				position: absolute;
				&.propdImg1 {
					width: 100px;
					height: 100px;
					top: 0;
					left: 0%;
					transform: translateX(-58%) translateY(53%);
				}
				&.propdImg2 {
					width: 100px;
					height: 100px;
					top: 0;
					left: 16%;
				}
				&.propdImg3 {
					width: 80px;
					height: 80px;
					top: 0;
					left: 45%;
					transform: translateY(90%);
				}
				&.propdImg4 {
					width: 160px;
					height: 150px;
					top: 0;
					right: 0%;
					transform: translateX(40%) translateY(-20%);
				}
				&.propdImg5 {
					width: 100px;
					height: 100px;
					top: 50%;
					transform: translateY(-55%) translateX(55%);
					right: 0%;
				}
				&.propdImg6 {
					width: 80px;
					height: 80px;
					bottom: 29%;
					left: 0%;
					transform: translateX(-40%);
				}
				&.propdImg7 {
					width: 165px;
					height: 175px;
					bottom: 0;
					left: 0%;
				}
				&.propdImg8 {
					width: 90px;
					height: 90px;
					bottom: 0;
					left: 50%;
					transform: translateY(-90%);
				}
				&.propdImg9 {
					width: 150px;
					height: 130px;
					bottom: 0;
					right: 0%;
					transform: translateX(40%) translateY(-20%);
				}
			}
		}

		.HomeBottomTextWrap {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);

			.HomeBottomText1,
			.HomeBottomText2 {
				white-space: nowrap;
				overflow: hidden;
			}

			.HomeBottomText1 {
				display: flex;
				padding-left: 15%;
				.comp-title {
					transform: translateX(-30%);
				}
			}
			.HomeBottomText2 {
				padding-right: 10%;
				.comp-title {
					transform: translateX(35%);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--HomeTopMargTop: var(--sp6x);
		--ShopNowContMaxWidth: 470px;
		--ShopNowContPad: var(--sp2x);
		--ShopNowContPadLeft: var(--sp2x);
		--backRoundSize: 560px;

		--ShopNowTickerContMarg: var(--sp12x);
		--ShopNowTickerItem: var(--sp3x);

		--HomeBottomMarg: var(--sp9x);
		--HomeBottomHeight: 620px;

		--muramonyRightWidth: 85%;

		.muramonyTopSection {
			.muramonyWrap {
				justify-content: center;
			}
		}

		.HomeBottom {
			.HomeBottomImgWrap {
				max-width: 1000px;
				.propdImg {
					&.propdImg1 {
						width: 156px;
						height: 150px;
						transform: translateX(-14%) translateY(53%);
					}
					&.propdImg2 {
						width: 130px;
						height: 120px;
						left: 30%;
					}
					&.propdImg3 {
						width: 120px;
						height: 115px;
						left: 55%;
						transform: translateY(135%);
					}
					&.propdImg4 {
						width: 210px;
						height: 200px;
						transform: translateX(15%) translateY(-15%);
					}
					&.propdImg5 {
						width: 140px;
						height: 135px;
						bottom: 17%;
						top: initial;
						transform: translateY(-27%) translateX(33%);
					}
					&.propdImg6 {
						width: 100px;
						height: 100px;
						transform: translateX(-40%);
					}
					&.propdImg7 {
						width: 245px;
						height: 235px;
						transform: translateY(11%);
					}
					&.propdImg8 {
						width: 125px;
						height: 125px;
						transform: translateY(-65%);
						left: 44%;
					}
					&.propdImg9 {
						width: 175px;
						height: 150px;
						right: 21%;
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		.HomeTop {
			min-height: 70vh;
			.HomeTopImgCont {
				.DragonImg {
					padding: 62%;
					transform: translate(-50%, 0%);
					top: 0%;
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--shopNowFont: 22px;
		--HomeTopMargBot: var(--sp5x);

		--muramonyLeftMargBot: var(--sp5x);
		--muramonyWrapPaddingLeft: var(--containerPadding);
		--muramonyWrapPaddingRight: 0;
		--muramonyTitleMarg: var(--sp7x);
		--muramonyLeftWidth: 45%;
		--muramonyRightWidth: calc(100% - var(--muramonyLeftWidth));

		.HomeTop {
			.HomeTopImgCont {
				.backRound {
					width: var(--backRoundSize);
					height: var(--backRoundSize);
					max-width: var(--backRoundSize);
					max-height: var(--backRoundSize);
				}
			}

			.cursorWrapp {
				.cursorDrag {
					top: 80%;
					left: 80%;
				}
			}
		}

		.muramonyTopSection {
			.muramonyWrap {
				align-items: center;
				.muramonyLeft {
					padding-right: var(--sp3x);
				}
			}
		}

		.HomeBottom {
			.HomeBottomBackImgWrap {
				.HomeBottomBackImg {
					img {
						object-fit: contain;
					}
				}
			}
		}

		.HomeTop {
			.CollNumber {
				bottom: 8vh;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--HomeTopMargTop: var(--sp1x);
		--ShopNowContMaxWidth: 470px;
		--ShopNowContPad: var(--sp2-5x);
		--ShopNowContPadLeft: var(--sp3x);
		--backRoundSize: 560px;

		--ShopNowTickerContMarg: var(--sp12x);
		--ShopNowTickerItem: var(--sp3x);

		--HomeBottomMarg: var(--sp12x);
		--HomeBottomHeight: 660px;

		.HomeTop {
			.CollNumber {
				bottom: inherit;
				top: calc(100vh - 120px);
			}
		}

		.HomeBottom {
			.HomeBottomImgWrap {
				max-width: 940px;
				.propdImg {
					&.propdImg1 {
						width: 170px;
						height: 160px;
						transform: translateX(-14%) translateY(53%);
					}
					&.propdImg2 {
						width: 145px;
						height: 130px;
						left: 30%;
					}
					&.propdImg3 {
						width: 125px;
						height: 125px;
						left: 55%;
						transform: translateY(135%);
					}
					&.propdImg4 {
						width: 225px;
						height: 210px;
						transform: translateX(15%) translateY(-15%);
					}
					&.propdImg5 {
						width: 150px;
						height: 145px;
						bottom: 17%;
						top: initial;
						transform: translateY(-27%) translateX(33%);
					}
					&.propdImg6 {
						width: 110px;
						height: 115px;
						transform: translateX(-40%);
					}
					&.propdImg7 {
						width: 260px;
						height: 255px;
						transform: translateY(11%);
					}
					&.propdImg8 {
						width: 135px;
						height: 135px;
						transform: translateY(-65%);
						left: 44%;
					}
					&.propdImg9 {
						width: 185px;
						height: 160px;
						right: 21%;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--shopNowFont: 26px;
		--letterSpaceTop: 6px;

		--HomeTopMargTop: calc(-1 * var(--sp5x));
		--ShopNowContMaxWidth: 530px;
		--ShopNowContPad: var(--sp2-5x);
		--ShopNowContPadLeft: var(--sp4x);
		--DragonImgMaxWidth: 640px;
		--backRoundSize: 720px;

		--ShopNowTickerContMarg: var(--sp15x);
		--ShopNowTickerPadd: var(--sp2x);
		--ShopNowTickerItem: var(--sp4x);

		--HomeBottomMarg: var(--sp10x);
		--HomeBottomHeight: 800px;

		--muramonyTitleMarg: var(--sp6x);
		--muramonyLeftWidth: 60%;

		--circleSize: var(--sp20x);

		.HomeBottom {
			.HomeBottomImgWrap {
				max-width: 1210px;
				.propdImg {
					&.propdImg1 {
						width: 205px;
						height: 190px;
						transform: translateX(-14%) translateY(53%);
					}
					&.propdImg2 {
						width: 175px;
						height: 155px;
						left: 30%;
					}
					&.propdImg3 {
						width: 150px;
						height: 150px;
						left: 55%;
						transform: translateY(135%);
					}
					&.propdImg4 {
						width: 275px;
						height: 255px;
						transform: translateX(15%) translateY(-15%);
					}
					&.propdImg5 {
						width: 180px;
						height: 175px;
						bottom: 17%;
						top: initial;
						transform: translateY(-27%) translateX(33%);
					}
					&.propdImg6 {
						width: 135px;
						height: 140px;
						transform: translateX(-40%);
					}
					&.propdImg7 {
						width: 320px;
						height: 310px;
						transform: translateY(11%);
					}
					&.propdImg8 {
						width: 160px;
						height: 160px;
						transform: translateY(-65%);
						left: 44%;
					}
					&.propdImg9 {
						width: 185px;
						height: 160px;
						right: 21%;
					}
				}
			}
		}

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-5deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(5deg);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--HomeTopMargBot: 0;

		.HomeTop {
			.homeTopTickerCont {
				transform: translateY(-75%);
			}
		}

		.HomeTop {
			.CollNumber {
				bottom: inherit;
				top: calc(100vh - 130px);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--shopNowFont: 28px;

		--HomeTopMargTop: calc(-1 * var(--sp1-3x));
		--ShopNowContMaxWidth: 540px;
		--ShopNowContPad: var(--sp2-5x);
		--ShopNowContPadLeft: var(--sp5x);
		--DragonImgMaxWidth: 790px;
		--backRoundSize: 880px;

		--ShopNowTickerContMarg: var(--sp20x);
		--ShopNowTickerPadd: var(--sp2x);
		--ShopNowTickerItem: var(--sp5x);

		--HomeBottomMarg: var(--sp15x);
		--HomeBottomHeight: 1152px;

		--muramonyLeftWidth: 57%;

		--circleSize: var(--sp23x);

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-5deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(5deg);
			}
		}

		.HomeBottom {
			.HomeBottomImgWrap {
				max-width: 1740px;
				.propdImg {
					&.propdImg1 {
						width: 290px;
						height: 275px;
						transform: translateX(-14%) translateY(53%);
					}
					&.propdImg2 {
						width: 250px;
						height: 220px;
						left: 30%;
					}
					&.propdImg3 {
						width: 220px;
						height: 215px;
						left: 55%;
						transform: translateY(135%);
					}
					&.propdImg4 {
						width: 390px;
						height: 370px;
						transform: translateX(15%) translateY(-15%);
					}
					&.propdImg5 {
						width: 260px;
						height: 250px;
						bottom: 17%;
						top: initial;
						transform: translateY(-27%) translateX(33%);
					}
					&.propdImg6 {
						width: 200px;
						height: 210px;
						transform: translateX(-40%);
					}
					&.propdImg7 {
						width: 455px;
						height: 445px;
						transform: translateY(11%);
					}
					&.propdImg8 {
						width: 230px;
						height: 230px;
						transform: translateY(-65%);
						left: 44%;
					}
					&.propdImg9 {
						width: 330px;
						height: 275px;
						right: 21%;
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--shopNowFont: 26px;

		--HomeTopMargTop: 0;
		--ShopNowContMaxWidth: 660px;
		--ShopNowContPad: var(--sp3x);
		--ShopNowContPadLeft: var(--sp7x);
		--DragonImgMaxWidth: 980px;
		--backRoundSize: 1120px;

		--ShopNowTickerContMarg: var(--sp24x);
		--ShopNowTickerPadd: var(--sp2-5x);
		--ShopNowTickerItem: var(--sp7x);

		--HomeBottomMarg: var(--sp28x);
		--HomeBottomHeight: 1350px;

		--muramonyLeftMargBot: var(--sp6x);
		--muramonyTitleMarg: var(--sp10x);
		--muramonyLeftWidth: 54%;

		--circleSize: var(--sp28x);

		.HomeTop {
			.homeTopTickerCont {
				transform: translateY(-75%);
			}
		}

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-4deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(4deg);
			}
		}

		.HomeBottom {
			.HomeBottomImgWrap {
				max-width: 2040px;
				.propdImg {
					&.propdImg1 {
						width: 340px;
						height: 325px;
						transform: translateX(-14%) translateY(53%);
					}
					&.propdImg2 {
						width: 290px;
						height: 260px;
						left: 30%;
					}
					&.propdImg3 {
						width: 260px;
						height: 250px;
						left: 55%;
						transform: translateY(135%);
					}
					&.propdImg4 {
						width: 460px;
						height: 430px;
						transform: translateX(15%) translateY(-15%);
					}
					&.propdImg5 {
						width: 305px;
						height: 295px;
						bottom: 17%;
						top: initial;
						transform: translateY(-27%) translateX(33%);
					}
					&.propdImg6 {
						width: 230px;
						height: 240px;
						transform: translateX(-40%);
					}
					&.propdImg7 {
						width: 535px;
						height: 525px;
						transform: translateY(11%);
					}
					&.propdImg8 {
						width: 270px;
						height: 270px;
						transform: translateY(-65%);
						left: 44%;
					}
					&.propdImg9 {
						width: 390px;
						height: 325px;
						right: 21%;
					}
				}
			}
		}
	}
`;

export default HomeStyle;
